import baseConfig from './baseConfig';

export default Object.freeze({
  ...baseConfig,
  env: {
    ...baseConfig.env,
    name: 'preprod',
    isPreprod: true,
  },
  features: {
    bugsnag: false,
    unionCall: false,
    telegramAuthWeb: true,
    telegramAuthDesktop: false,
    googleAuthWeb: true,
    googleAuthDesktop: false,
  },
  amplitudeApiKey: 'ed0ed8a9fa353e625ee9cece1bb83184',
  reCaptchaKey: '6LffJgojAAAAAIDI_xD2C7h57_L0OvPi1DFzp62B',
  deeplinkHost: 'https://ms8s.test-app.link',
  googleClientId: '274662028981-5rid6hit92o3oo9g4dubkfa3vjvo6ukh.apps.googleusercontent.com',
});
